import { Component } from '@angular/core';

import {TranslateService} from '@ngx-translate/core';

import { Device } from '@capacitor/device';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  public language: string;

  constructor(public translate: TranslateService) {
    Device.getLanguageCode().then((info) => {
      console.log(info);
      this.language = info.value;
      translate.use(this.language);

    });
  }


}
