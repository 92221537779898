import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { HttpClientModule, HttpClient } from '@angular/common/http';
import { AngularTokenModule} from '../../projects/angular-token/src/public_api';

import { FormsModule } from '@angular/forms';

import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';

import { SwiperModule } from 'swiper/angular';

import { JoyrideModule } from 'ngx-joyride';

import { NgChartsModule } from 'ng2-charts';

import { TranslateModule, TranslateLoader, TranslatePipe} from '@ngx-translate/core';
import { TranslateHttpLoader} from '@ngx-translate/http-loader';

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        HttpClientModule,
        TranslateModule.forRoot({
            defaultLanguage: 'en',
            loader: {
              provide: TranslateLoader,
              useFactory: (createTranslateLoader),
              deps: [HttpClient]
            }
          }),
        SwiperModule,
        FormsModule,
        NgChartsModule,
        JoyrideModule.forRoot(),
        AngularTokenModule.forRoot({
            apiBase: 'https://skillsnt.io',
            //apiBase: 'http://localhost:3000',
            apiPath: 'api/v1'
        }),
        AppRoutingModule
    ],
    providers: [
        InAppBrowser,
        TranslatePipe,
        {
            provide: RouteReuseStrategy,
            useClass: IonicRouteStrategy
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
