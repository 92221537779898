import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'initiation',
    loadChildren: () => import('./initiation/initiation.module').then( m => m.InitiationPageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'lvl-detail',
    loadChildren: () => import('./lvl-detail/lvl-detail.module').then( m => m.LvlDetailPageModule)
  },
  {
    path: 'training',
    loadChildren: () => import('./training/training.module').then( m => m.TrainingPageModule)
  },
  {
    path: 'seleccion',
    loadChildren: () => import('./seleccion/seleccion.module').then( m => m.SeleccionPageModule)
  },
  {
    path: 'pruebas',
    loadChildren: () => import('./pruebas/pruebas.module').then( m => m.PruebasPageModule)
  },
  {
    path: 'workouts-detail',
    loadChildren: () => import('./workouts-detail/workouts-detail.module').then( m => m.WorkoutsDetailPageModule)
  },
  {
    path: 'training-workout/:workoutId',
    loadChildren: () => import('./training-workout/training-workout.module').then( m => m.TrainingWorkoutPageModule)
  },
  {
    path: 'training-workout-speed',
    loadChildren: () => import('./training-workout-speed/training-workout-speed.module').then( m => m.TrainingWorkoutSpeedPageModule)
  },
  {
    path: 'training-workout-drill',
    loadChildren: () => import('./training-workout-drill/training-workout-drill.module').then( m => m.TrainingWorkoutDrillPageModule)
  },
  {
    path: 'pruebas-take',
    loadChildren: () => import('./pruebas-take/pruebas-take.module').then( m => m.PruebasTakePageModule)
  },
  {
    path: 'pruebas-result',
    loadChildren: () => import('./pruebas-result/pruebas-result.module').then( m => m.PruebasResultPageModule)
  },
  {
    path: 'library',
    loadChildren: () => import('./library/library.module').then( m => m.LibraryPageModule)
  },
  {
    path: 'collections/:essentialTest',
    loadChildren: () => import('./collections/collections.module').then( m => m.CollectionsPageModule)
  },
  {
    path: 'collection-subcat/:collectionId',
    loadChildren: () => import('./collection-subcat/collection-subcat.module').then( m => m.CollectionSubcatPageModule)
  },
  {
    path: 'collection-subcat-groups/:subcollectionId',
    loadChildren: () => import('./collection-subcat-groups/collection-subcat-groups.module').then( m => m.CollectionSubcatGroupsPageModule)
  },
  {
    path: 'collection-video',
    loadChildren: () => import('./collection-video/collection-video.module').then( m => m.CollectionVideoPageModule)
  },
  {
    path: 'courses/:pilares',
    loadChildren: () => import('./courses/courses.module').then( m => m.CoursesPageModule)
  },
  {
    path: 'course-show/:courseId',
    loadChildren: () => import('./course-show/course-show.module').then( m => m.CourseShowPageModule)
  },
  {
    path: 'course-video',
    loadChildren: () => import('./course-video/course-video.module').then( m => m.CourseVideoPageModule)
  },
  {
    path: 'course-workout/:workoutId',
    loadChildren: () => import('./course-workout/course-workout.module').then( m => m.CourseWorkoutPageModule)
  },
  {
    path: 'course-test',
    loadChildren: () => import('./course-test/course-test.module').then( m => m.CourseTestPageModule)
  },
  {
    path: 'wereables',
    loadChildren: () => import('./wereables/wereables.module').then( m => m.WereablesPageModule)
  },
  {
    path: 'pruebas-skill/:skillId',
    loadChildren: () => import('./pruebas-skill/pruebas-skill.module').then( m => m.PruebasSkillPageModule)
  },
  {
    path: 'pruebas-subskill/:subSkillId',
    loadChildren: () => import('./pruebas-subskill/pruebas-subskill.module').then( m => m.PruebasSubskillPageModule)
  },
  {
    path: 'training-today/:workoutId',
    loadChildren: () => import('./training-today/training-today.module').then( m => m.TrainingTodayPageModule)
  },
  {
    path: 'training-create',
    loadChildren: () => import('./training-create/training-create.module').then( m => m.TrainingCreatePageModule)
  },
  {
    path: 'essentials',
    loadChildren: () => import('./essentials/essentials.module').then( m => m.EssentialsPageModule)
  },
  {
    path: 'essentials-subcat/:essentialId',
    loadChildren: () => import('./essentials-subcat/essentials-subcat.module').then( m => m.EssentialsSubcatPageModule)
  },
  {
    path: 'training-create-improve',
    loadChildren: () => import('./training-create-improve/training-create-improve.module').then( m => m.TrainingCreateImprovePageModule)
  },
  {
    path: 'pruebas-show',
    loadChildren: () => import('./pruebas-show/pruebas-show.module').then( m => m.PruebasShowPageModule)
  },
  {
    path: 'training-create-coach',
    loadChildren: () => import('./training-create-coach/training-create-coach.module').then( m => m.TrainingCreateCoachPageModule)
  },
  {
    path: 'personal-coach',
    loadChildren: () => import('./personal-coach/personal-coach.module').then( m => m.PersonalCoachPageModule)
  },
  {
    path: 'training-chat',
    loadChildren: () => import('./training-chat/training-chat.module').then( m => m.TrainingChatPageModule)
  },
  {
    path: 'training-meetroom',
    loadChildren: () => import('./training-meetroom/training-meetroom.module').then( m => m.TrainingMeetroomPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
